<script setup lang="ts">
import { useWindowScroll } from '@vueuse/core'
import Navigation from './Navigation.vue'
import Logo from './Logo.vue'
import User from './User.vue'
import Service from './Service.vue'
import SideNavigation from './SideNavigation.vue'
import Lang from '@/components/lang'

const { y } = useWindowScroll()
const showBg = ref(false)

// 用 computed 有时会没背景，所以用 watch
watch(y, (y) => {
  showBg.value = y > 30
})
</script>

<template>
  <header class="sticky z-5 top-0" :class="{ 'bg-header': showBg }">
    <div class="relative grid grid-cols-[auto_1fr_auto_auto_auto] items-center px-[25px] gap-[10px] mx-auto min-h-[80px] lt-laptop:grid-cols-2 lt-laptop:min-h-50px">
      <SideNavigation class="hidden lt-laptop:block lt-laptop:text-white" /> <!-- 小屏导航 -->
      <Logo class="lt-laptop:abs-center lt-laptop:min-w-[130px] lt-laptop:scale-[0.9]" />
      <Navigation class="hidden flex-auto laptop:block" /> <!-- 大屏导航	-->
      <User />
      <Service class="hidden laptop:block" />
      <ClientOnly>
        <Lang class="lt-laptop:justify-self-end lang" styleName="s2" />
      </ClientOnly>
    </div>
  </header>
</template>

<style lang="scss" scoped>
.bg-header {
  background: linear-gradient(180deg, #25335dbc, #1a213bbc);
  backdrop-filter: saturate(200%) blur(20px);
  box-shadow: 1px 0 9px 1px #0d111791;
}

.lang {
  @media bp-lt-laptop {
    :deep(.current-lang) {
      --uno: 'p-4px h-28px';

      .icon {
        --uno: 'text-18px';
      }

      span {
        --uno: 'hidden';
      }
    }
  }

  :deep(.lang-options) {
    --uno: '-right-[10px] text-left';
  }
}
</style>
